export const PROFILE_LOADING = 'PROFILE_LOADING';
export const SET_USER_PROFILE_AVATAR = 'SET_USER_PROFILE_AVATAR';
export const CLEAR_PROFILE_ERROR = 'CLEAR_PROFILE_ERROR';
export const LOGIN_WITH_EMAIL = 'LOGIN_WITH_EMAIL';

export const SETTINGS_LOADING = 'SETTINGS_LOADING';
export const SET_COMPANY_AVATAR = 'SET_COMPANY_AVATAR';
export const CLEAR_SETTINGS_ERROR = 'CLEAR_SETTINGS_ERROR';
export const SET_SETTINGS = 'SET_SETTINGS';

export const SET_AUTHENTICATION_STATE = 'SET_AUTHENTICATION_STATE ';
export const CLEAR_AUTHENTICATION_STATE = 'CLEAR_AUTHENTICATION_STATE ';
export const SET_REGISTRATION_STATE = 'SET_REGISTRATION_STATE ';

export const SET_USER_PROFILE = 'SET_USER_PROFILE';

export const SET_USERS = 'SET_USERS';
export const ADD_USER = 'ADD_USER';
export const ADD_USERS = 'ADD_USERS';

export const SET_DASHBOARD_SUMMARY = 'SET_DASHBOARD_SUMMARY';
export const UPDATE_DASHBOARD_SUMMARY = 'UPDATE_DASHBOARD_SUMMARY';

export const UPDATE_PAGE_TITLE = 'UPDATE_PAGE_TITLE';
export const SET_DIALOG_STATE = 'SET_DIALOG_STATE';
