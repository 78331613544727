import clsx from 'clsx';
import {
  Container,
  CircularProgress,
  LinearProgress,
  Avatar,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
  Box,
  Grid,
  InputBase,
  Badge,
  MenuItem,
  Menu,
  Drawer,
  AppBar,
  Button,
  Toolbar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItemIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  colors,
  useTheme,
  SvgIcon,
  InputAdornment
} from './core';

import {
  SupervisedUserIcon,
  VerifiedUserIcon,
  AccountCircle,
  LockOutlinedIcon,
  NotificationsOutlinedIcon,
  NotificationsIcon,
  MoreIcon,
  MenuIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  InboxIcon,
  MailIcon,
  LockIcon,
  AccountBalanceWalletIcon,
  AccountBalanceIcon,
  MoneyIcon,
  ExitToAppIcon,
  DashboardIcon,
  PeopleAltIcon,
  SettingsIcon,
  StoreIcon,
  PollIcon,
  ArrowRightIcon,
  ArrowDownwardIcon,
  ArrowDropDownIcon,
  PhotoCameraIcon,
  PeopleIcon
} from './icons';

export {
  // UI Components
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  colors,
  useTheme,
  SvgIcon,
  InputAdornment,
  Container,
  CircularProgress,
  LinearProgress,
  Avatar,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
  Box,
  Grid,
  InputBase,
  Badge,
  MenuItem,
  Menu,
  clsx,
  Drawer,
  AppBar,
  Button,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  // Icons
  SupervisedUserIcon,
  PeopleIcon,
  PhotoCameraIcon,
  ArrowRightIcon,
  ArrowDropDownIcon,
  ArrowDownwardIcon,
  VerifiedUserIcon,
  NotificationsOutlinedIcon,
  AccountBalanceWalletIcon,
  AccountBalanceIcon,
  MoneyIcon,
  ExitToAppIcon,
  DashboardIcon,
  PeopleAltIcon,
  SettingsIcon,
  StoreIcon,
  PollIcon,
  AccountCircle,
  LockOutlinedIcon,
  NotificationsIcon,
  MoreIcon,
  MenuIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  InboxIcon,
  MailIcon,
  LockIcon
};
